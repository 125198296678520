<template>
    <div>
        <!-- <div class="card" id="availableMachines">
            <h3 class="card_title">Maschinendatenbank</h3>
            <div class="padding">
                <div id="propertyContainer" v-if="catalogue_selected">
                    <h3>{{catalogue_selected}}</h3>
                    <div id="propertyGrid" v-for="(mat, i) in machineInformation" :key="i" class="flex indent">
                        <p style="flex: 1">{{dict(mat.name)}}</p>
                        <input type="text" :value="mat.value" @input="customizeMachine(i, $event.target.value)">
                        <p style="flex: 1">{{dict(mat.unit)}}</p>
                    </div>
                    <div id="machine_useData" @click="applyPreview()" class="indent textspacing padding_small pointer radius blue flex fitContent">
                        <img src="https://dashboard.pixelprime.de/assets/icons/outbound.svg"> 
                        <p style="margin: auto .25em">Daten übernehmen</p>
                    </div>                    
                    <div v-if="isCustomized" id="machine_useData" @click="saveCustomized()" class="indent padding_small pointer radius blue flex fitContent">
                        <img src="https://kalkulation.primecalc.de/assets/images/icons/save.svg"> 
                        <p style="margin: auto .25em">Anpassung speichern</p>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="card">
            <h3 class="card_title">
                <indicator class="marginAuto" :condition="!verifiedSteps.machines" required="1" :ttle="verifiedSteps.machines"></indicator>
                Gewählte Maschinen
                </h3>
            <div class="padding">
                <!-- <p v-if="!$store.state.calculation.processes.length">Fügen Sie erst Fertigungsprozesse hinzu, ordnen Sie dann Maschinen zu.</p> -->
                <!-- <div v-else> -->
                    <!-- <div class="box">
                        <p class="textspacing">Ordnen Sie den erstellten Prozessen Maschinen zu.</p>
                        <div id="processes" class="flex textspacing">
                            <p :class="{blue: process_selected == i}" @click="process_selected=i" class="pointer dark padding_small radius" v-for="(p, i) in $store.state.calculation.processes" :key="i">{{dict(p.type)}}</p>
                        </div>
                    </div> -->

                    <div class="box flex">
                        <p style="margin: auto 8px auto 0;"><b>Suche:</b></p>
                        <p v-if="!machines.length">Lade Maschinen</p>
                        <searchlist v-else :values="machines" :placholder="machine.name" :firstsel="catalogue_selected" @select="changeSelected($event)"></searchlist>
                        <img v-if="machine.custom" @click="$store.commit('machine_delete', machine.name)" src="https://dashboard.pixelprime.de/assets/icons/delete.svg" class="pointer" style="margin-left: 1em" alt="">
                    </div>

                    <div class="box">
                        <p class="textspacing"><b>Zugeordnete Maschine:</b></p>
                        <div v-if="machine" id="machineGrid" class="indent">
                            <p>Name:</p>
                            <input style="grid-area: 1/2/2/4" type="text" :value="machine.name" @input="changeMachineValue('name', $event.target.value)">
                            <p>Maschinenstundesatz:</p>
                            <input type="number" :value="machine.machinecost_hourly" @input="changeMachineValue('machinecost_hourly', $event.target.value)">
                            <p>€/h</p>
                            <p>Werkzeugwechselzeit:</p>
                            <input type="number" :value="machine.toolchange_time" @input="changeMachineValue('toolchange_time', $event.target.value)">
                            <p>s</p>
                            <p>Umspannzeit:</p>
                            <input type="number" :value="machine.piecechange_time" @input="changeMachineValue('piecechange_time', $event.target.value)">
                            <p>s</p>
                        </div>
                        <p v-else class="indent">Wählen Sie erst einen Prozess</p>
                    </div>
                    
                    <div class="box">
                        <p @click="machine_save()" class="padding_small blue radius pointer fitContent">Anpassung speichern</p>
                        <p>{{save_message}}</p>
                    </div>
                    <!-- <div class="flex">
                        <div id="processes" style="flex: 1">
                            <p><b>Prozesse:</b></p>
                            <div :key="p" v-for="(process, p) in processes_created" class="flex">
                                <div @click="process_selected = p" class="indent padding_small radius dark fitContent pointer textspacing">
                                    {{dict(process.type)}}
                                </div>
                                <searchlist :values="machines" :value="process.machine" :firstsel="process.machine" @select="changeChosenMachine(p, $event)"></searchlist>
                            </div>
                        </div>
                        <div id="machineInfo" style="flex: 1">
                            <p><b>Maschinendaten:</b></p>
                            <div class="indent">
                                <div v-for="(val, key) in machineInformation" :key="key">
                                    <p><b>{{dict(val.name)}}</b></p>
                                    <p>{{val.value}}</p>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            <!-- </div> -->
        </div>
        
        <!-- <available-machines></available-machines> -->
        <!-- <new-machine></new-machine>
        <setuptime></setuptime> -->

    </div>
</template>

<script>

    export default {
        name: "Machine",
        data() {
            return {         
                save_message: "",
                // information_raw: {},
                // refresh: 0,
                // isCustomized: 0,
                // machineInformation: {},
                process_selected: 0,
                // machine_selected: 0
            }
        },
        // mounted() {
        //     this.getMachines()
        // },
        // watch: {
        //     catalogue_selected() {
        //         this.getMachineInformation(this.catalogue_selected)
        //     }
        // },
        computed: {
            machines() {
                return this.$store.getters.machines
            },
            catalogue_selected() {
                return this.$store.getters.machines_getCatalogueSelected
            },
            machineInformation() {
                return this.$store.getters.getMachineInformation(this.catalogue_selected)
            },
            isCustomized() {
                if(!this.catalogue_selected) return false
                return this.$store.getters.machine_isCustomized(this.catalogue_selected)
            },

            machine() {
                if(!this.$store.state.calculation.processes[this.$store.state.process_selected]) return 0
                let machine = this.$store.state.calculation.processes[this.$store.state.process_selected].machine
                if(!machine) machine = {}
                if(!machine.name) machine.name = ""
                if(!machine.machinecost_hourly) machine.machinecost_hourly = ""
                if(!machine.toolchange_time) machine.toolchange_time = ""
                if(!machine.piecechange_time) machine.piecechange_time = ""
                return machine
            },
            // processes_created() {
            //     return this.$store.state.calculation.processes || 0
            // },
            // catalogue_selected() {
            //     return this.$store.state.machine_selected
            // },
            // machine() {
            //     console.log("fleop");
            //     if(!this.$store.state.calculation.processes[this.process_selected]) return 0
            //     return this.$store.state.calculation.processes[this.process_selected].machine
            // },
            verifiedSteps() {
                return this.$store.getters.verifiedSteps
            }
            
        },
        methods: {
            dict(c) {
                return this.$store.state.dict[c] || c
            },
            changeSelected(machine) {
                this.$store.commit("machine_changeSelected", machine)
                this.applyPreview()
            },
            changeMachineValue(key, value) {
                this.$store.commit("changeMachineValue", [this.$store.state,this.process_selected, key, value])
                this.$store.dispatch("refresh")
            },
            applyPreview() {
                // this.$store.commit("changeMachineValue", [this.$store.state, this.process_selected, "name", this.catalogue_selected])
                for (const param in this.machineInformation) {
                    this.$store.commit("changeMachineValue", [this.$store.state,this.process_selected, param, this.machineInformation[param].value])
                }
                // this.machineInformation.forEach(info => {
                // });
                this.$store.dispatch("refresh")
            },
            machine_save() {
                this.save_message = ""
                if (!this.machine.name) {
                    this.save_message = "Bitte geben Sie der Maschine einen Namen"
                    return 
                }
                this.$store.commit("machine_saveNew", this.machine)
            }
        }
    };

</script>

<style scoped>
    indicator{
        margin: auto;
    }
    #propertyGrid, #machineGrid{
        display: grid;
        grid-template-columns: 1fr 4em 5em;
        grid-column-gap: 1em;
        grid-row-gap: 4px;
        margin-bottom: 4px;
    }
</style>